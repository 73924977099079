export const normalizeAdditionalVerbiage = verbiage => {
  if (!verbiage || !verbiage.description) { return verbiage };

  const { title, description, noAffiliate } = verbiage;

  const normalizedDescription = Array.isArray(description)
    ? description.join(" \n ")
    : description;

  return {
    title,
    reason: normalizedDescription,
    noAffiliate,
  }
}
