export default {
  default_message: {
    title: "We're sorry.",
    description: [
      "We're unable to proceed with your application. Please check back with your agent for other options.",
    ],
  },
  decline_dynamic_message: {
    title: "We're sorry.",
    description: [
      "We're unable to proceed with your application",
      "Please check back with @DisplayName for other options.",
    ],
  },
  decline_static_message: {
    title: "We're sorry.",
    description: [
      "We're unable to proceed with your application",
      "Please check back with your agent for other options.",
    ],
  },
};
