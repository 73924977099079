import React, { Fragment } from "react";
import styled from "styled-components";
import { BoundedParagraph } from "./BoundedParagraph";

const DeclineExplanationText = styled(BoundedParagraph)`
  margin-bottom: ${(props) => props.theme.spacing.xsmall};
  max-width: 48ch;
`;

interface Props {
  descriptionText: string[];
}

export const DeclineDescription: React.FC<Props> = ({ descriptionText }) => (
  <Fragment>
    {//TODO: refactor this once optional chaining is enabled
      descriptionText &&
        descriptionText.map((paragraph, i) => {
          return (
            <DeclineExplanationText key={i} data-cy="declineReason">
              {paragraph}
            </DeclineExplanationText>
          );
        })
    }
  </Fragment>
);
