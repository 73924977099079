import React from "react";
import { IconTheBaileyGroup } from "../Icons/IconTheBaileyGroup";
import { IconHub } from "../Icons/IconHub";
import { IconQuotacy } from "../Icons/IconQuotacy";
import { IconPolicyGenius } from "../Icons/IconPolicyGenius";
import { generatePolicyGeniusUrl } from "./generatePolicyGeniusUrl";
import { keyMappings } from "../data/partnerKeyMappings";

export const COVID_REASON = "covid";
export const DEFAULT_PARTNER = "bestow" as string; // this controls which partner data is loaded when no partner_referral is provided
export const DYNAMIC_PARTNER_DEFAULT = keyMappings.quotacy;
export const GENERIC_PARTNER = "generic_agent";
export const DEFAULT_REASON = "default_message";
export const FALLBACK_NEXTSTEP_NOAGENT = "We’re working hard to expand insurability and will reach out if this changes."
export const FALLBACK_NEXTSTEP_AGENT = "We're unable to approve your application. Please check back with your agent for other options.";
export const TEMPORARILY_UNAVAILABLE =
  "You’ll have the opportunity to apply again in 60 days after you have fully recovered and we’ll be sure to reach out with a friendly reminder then.";
export const CUSTOM_DYNAMIC_REASON = "decline_dynamic_message";
export const CUSTOM_STATIC_REASON = "decline_static_message";

// Adding values like generated strings or components will make them available within decline content with the "@Notation"
export const partnerMap = {
  [keyMappings.theBaileyGroup]: {
    logo: <IconTheBaileyGroup width="280px" />,
  },
  [keyMappings.hubInternational]: {
    logo: <IconHub width="180px" />,
  },
  [keyMappings.policyGenius]: {
    logo: <IconPolicyGenius width="180px" />,
    // policyGeniusUrl is dynamically provided down as props to the component originating in generatePolicyUrl
    // The reason for this is that it needs to have certain query params attached to it.
    // This function accepts query param data and generates a link from it
    link: generatePolicyGeniusUrl,
  },
  [keyMappings.quotacy]: {
    logo: <IconQuotacy width="180px" />,
    link: "https://www.quotacy.com/bestow",
  },
};

export const generatePartnerData = ({ currentPartner = DEFAULT_PARTNER, dynamicPartner, ...rest }) => ({
  name: currentPartner,
  ...(partnerMap[currentPartner] || {}),
  dynamicPartner: partnerMap[dynamicPartner || DYNAMIC_PARTNER_DEFAULT],
  ...rest,
});
