import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import * as Sentry from "@sentry/browser";

import App from "./App";
import { getNewRelicSnippet } from './utils';
import { env } from './interfaces';

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://3a0e82cf9bbf4f34bcdb6487f3d0e07f@sentry.io/1792671",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "dev"
  });
}

const currentScriptElement = document.currentScript as HTMLScriptElement;
const parentNode = currentScriptElement.parentNode as Node;
const rootElement = document.createElement("div");
rootElement.setAttribute("id", "bestow-root-el");
const tooltipElement = document.createElement("div");
tooltipElement.setAttribute("id", "tooltip-root");

parentNode.insertBefore(rootElement, currentScriptElement);
parentNode.insertBefore(tooltipElement, rootElement);

const snippetSrc = getNewRelicSnippet(process.env.REACT_APP_ENVIRONMENT as env);
if (snippetSrc !== undefined) {
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('src', snippetSrc);
  document.head.insertBefore(scriptTag, document.head.firstElementChild);
}

(async () => {
  ReactDOM.render(
    <App />,
    document.getElementById("bestow-root-el")
  );
})();

export { DeclineView } from "./components";
