/**
 *
 * @param {string} num phoneNumber
 * @returns {string} formatted phone number
 * for decline screen preview
 */
export function formatPhoneNumber(num: string): string {
  const ext = num.slice(0, 3);
  return `(${ext}) ${num.slice(4)}`;
}
