import React from "react";

import { redirectToQuote } from "../utils";

class Redirect extends React.Component {
  componentDidMount() {
    redirectToQuote()
  }

  render() {
    return null;
  }
}

export default Redirect;
