import { PARTNERS } from "../data/partnerConstants";
import { keyMappings } from "../data/partnerKeyMappings";
import { PartnerTemplate } from "../../../interfaces";

/**
 * Returns partner data, but returns a default if it cant find the specified partner.
 * The default is configureable from the second parameter.
 *  Motivation for adding this was because we want to split traffic between policyGenius and Quotacy via LaunchDarkly
 * @param partnerName : string - The desired partner data - must map to PARTNERS.keyMappings
 * @param defaultPartnerFlag : string - Default partner value coming from Launchdarkly
 */

export const getPartnerDeclineData = (
  partnerName: string | undefined | null,
  defaultPartnerFlag: string,
  isAgent: boolean,
): PartnerTemplate => {
  const defaultPartner =
    (isAgent ? keyMappings.agent : defaultPartnerFlag) ||
    PARTNERS.defaultPartner;

  const partnerExists = Object.keys(PARTNERS).includes(partnerName || '');
  const _partnerName = (partnerExists && partnerName) || defaultPartner;
  return PARTNERS[_partnerName as 'tbg'];
};
