import React from "react";
import Zander from '../../../images/zanderinsurance.svg';
import { optimizePhoneUrl } from "../../../utils/optimizePhoneUrl";
import styled from "styled-components";

interface Props {
    telephone: string;
}

const StyledLogo = styled.img`
    max-width: 250px;
`;

const ZanderLogo = (props: Props) =>
<div className="flex justify-center">
    <a href={`tel:${optimizePhoneUrl(props.telephone)}`} rel="noopener noreferrer" target="_blank">
        <StyledLogo src={Zander} alt="Zander Insurance" />
    </a>
</div>;

export default ZanderLogo;
