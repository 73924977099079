import React from "react";
import { keyMappings } from "../../Decline/data/partnerKeyMappings";

export const IconHub = props => (
  <svg width="113px" height="35px" viewBox="0 0 113 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" data-cy={keyMappings.hubInternational + "-icon"} {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#00AEEF" offset="0%"></stop>
        <stop stopColor="#0095DA" offset="21.4579814%"></stop>
        <stop stopColor="#0071B4" offset="51.1296453%"></stop>
        <stop stopColor="#0061AF" offset="73.5747466%"></stop>
        <stop stopColor="#293996" offset="100%"></stop>
      </linearGradient>
      <path d="M17.5,0 C7.799,0 0,7.8 0,17.5 L0,17.5 C0,27.201 7.799,35 17.5,35 L17.5,35 C27.2,35 35,27.201 35,17.5 L35,17.5 C35,7.8 27.2,0 17.5,0 Z M7.099,17.5 C7.099,11.8 11.8,7.1 17.5,7.1 L17.5,7.1 C23.2,7.1 27.9,11.8 27.9,17.5 L27.9,17.5 C27.9,23.2 23.2,27.901 17.5,27.901 L17.5,27.901 C11.8,27.901 7.099,23.2 7.099,17.5 L7.099,17.5 Z" id="path-2"></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2---Embedded-Quote-Lite-Error-HUB" transform="translate(-194.000000, -174.000000)">
        <g id="Group-2" transform="translate(50.000000, 94.000000)">
          <g id="HUB" transform="translate(144.000000, 80.000000)">
            <path d="M103.2998,19.5 L99.6998,19.5 L99.6998,22.7 L103.2998,22.7 C104.9008,22.7 105.6998,22.1 105.6998,21.1 L105.6998,21 C105.6998,20.1 104.9008,19.5 103.2998,19.5 L103.2998,19.5 Z M105.0998,13.5 C105.0998,12.5 104.2998,12 102.7998,12 L99.6998,12 L99.6998,15 L102.7998,15 C104.4008,15.1 105.0998,14.5 105.0998,13.5 L105.0998,13.5 Z M92.6998,6.6 L104.6998,6.6 C107.6998,6.6 109.5998,7.3 110.7998,8.5 C111.5998,9.3 112.0998,10.4 112.0998,11.8 L112.0998,12 C112.0998,14.5 110.5998,16.1 108.2998,16.9 C111.0998,17.7 112.7998,19.2 112.7998,22.1 L112.7998,22.2 C112.7998,25.6 109.9998,28.2 104.4998,28.2 L93.9998,28.2 C93.2998,28.2 92.6998,27.6 92.6998,26.9 L92.6998,6.6 Z M69.1998,18.5 L69.1998,6.6 L75.1998,6.6 C75.9008,6.6 76.4998,7.2 76.4998,7.9 L76.4998,18.3 C76.4998,21 77.9008,22.1 79.7998,22.1 C81.6998,22.1 83.0998,21.1 83.0998,18.4 L83.0998,8 C83.0998,7.3 83.6998,6.7 84.4008,6.7 L90.4008,6.7 L90.4008,18.4 C90.4008,25.7 86.0998,28.6 79.6998,28.6 C73.2998,28.5 69.1998,25.6 69.1998,18.5 L69.1998,18.5 Z M45.9998,6.6 L53.1998,6.6 L53.1998,14.2 L59.7998,14.2 L59.7998,6.6 L66.9998,6.6 L66.9998,26.8 C66.9998,27.5 66.4008,28.1 65.6998,28.1 L59.9008,28.1 L59.9008,20.4 L53.2998,20.4 L53.2998,28.1 L45.9998,28.1 L45.9998,6.6 Z" id="Fill-1" fill="#263746"></path>
            <mask id="mask-3" fill="white">
              <use xlinkHref="#path-2"></use>
            </mask>
            <use id="Clip-4" fill="url(#linearGradient-1)" xlinkHref="#path-2"></use>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
