import React, { Component } from "react";
import { Card } from "@bestowinc/level19";
import styled from "styled-components";
import { Level19ThemeProvider, BLUE_THEME_2021 } from "@bestowinc/level19";

import { Decline, Redirect } from "./components";
import {
  setContainerSizeToForm,
  listenForHeightRequest,
} from "./utils";

const DeclineCard = styled(Card)`
  border: none;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  padding: 0;
`;


class App extends Component {
  state = { ignoreData: false };
  componentDidMount() {
    if (window) {
      window.onresize = setContainerSizeToForm;
    }

    setContainerSizeToForm();
    listenForHeightRequest();
  }

  componentDidCatch(e: Error, i: any) {
    console.error(e, i);
    this.setState({ ignoreData: true });
  }

  render() {
    return (
      <Level19ThemeProvider
        style={{ position: "relative" }}
        className="bestow-top-node"
        theme={BLUE_THEME_2021}
      >
          <DeclineCard>
            {window.location.pathname === '/decline' ? (
              <Decline />
            ) : (
              <Redirect />
            )}
          </DeclineCard>
      </Level19ThemeProvider>
    )
  }
}

export default App;
export * from "./components";
