/**
 * Emit a segment event
 * @param name - the name of the event. NOTE that this creates an entire table on the DB, so be careful with naming
 * @param payload - data that will turn into columns on the DB so also be careful
 * @returns {*}
 */
export const segmentTrack = (name: string, payload: any = null) => {
  //window.analytics is segment
  //note: segment is omitted from price-reckoner in order to keep the build lean, so this must be a part of the parent page
  if ((window as any).analytics) {
    return (window as any).analytics.track(name, payload);
  }
};
