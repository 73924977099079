export const redirectToQuote = () => {
  const { pathname, search } = window.location;
  const { REACT_APP_AGS_API_URL, REACT_APP_AGENT_QUOTE_URL } = process.env;

  const shortID = pathname.substring(1, pathname.length);
  const agentAPIURL = REACT_APP_AGS_API_URL + '/agent-urls/short-id/' + shortID;
  console.group("redirecing", REACT_APP_AGENT_QUOTE_URL, REACT_APP_AGS_API_URL);
  fetch(agentAPIURL)
    .then(async res => {
      const agentURL = (await res.json()).agent_url;
      const query = combineQueryParameters(search, agentURL)
      window.location.replace(`${REACT_APP_AGENT_QUOTE_URL}/agents/${agentURL.pathname}/${query}`);
    })
    .catch(err => {
      console.error(err);
      window.location.replace(`${REACT_APP_AGENT_QUOTE_URL}/quote`);
    })

  function combineQueryParameters(queryString, agentURL) {
    let combinedQueryString = '';
    if (!queryString) {
      combinedQueryString = `?u=${agentURL.short_id}`;
    } else {
      combinedQueryString = queryString + `&u=${agentURL.short_id}`;
    }

    const { agent_name, agency_name, bga, imo, pathname } = agentURL;
    const UTMSource = agent_name || agency_name || bga || imo || pathname;

    return combinedQueryString + `&utm_source=${UTMSource}&utm_medium=agents`;
  }
}