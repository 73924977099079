import React, { Fragment } from "react";
import { Link, LinkArrowIcon } from "@bestowinc/level19";

interface LinkProps {
  partnerUrl?: string;
}

export const PartnerLink: React.SFC<LinkProps> = ({partnerUrl, children}) => {
  return (
    <Fragment>
      <Link
        iconRight={<LinkArrowIcon />}
        target="_blank"
        href={partnerUrl}
        loading={false}
      >
        {children}
      </Link>
    </Fragment>
  );
};

interface PhoneLinkProps {
  phoneNumber: string;
}

export const PartnerPhoneLink: React.SFC<PhoneLinkProps> = props => {
  return (
    <PartnerLink partnerUrl={"tel:" + props.phoneNumber}>
      Please call {props.phoneNumber} {props.phoneNumber === '866-207-8988' ? 'option 4' : null}
    </PartnerLink>
  );
};
