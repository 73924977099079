import React, { Fragment } from "react";
import { H1 } from "@bestowinc/level19";
import styled from "styled-components";
import declineVerbiage from "./data/agentDeclineVerbiage";
import { get } from "lodash";
import { getPartnerDeclineData } from "./utils/getPartnerDeclineData";
import { replaceTemplateKeywords } from "../../utils/replaceTemplateKeywords";
import { normalizeAdditionalVerbiage } from "./utils/normalizeAdditionalVerbiage";
import { DeclineDescription } from "./components/DeclineDescription";
import { PartnerCTA } from "./components/PartnerCTA";
import { CustomDeclineCTA } from "./components/CustomDeclineCTA";
import { keyMappings } from "./data/partnerKeyMappings";
import ZanderLogo from "./components/ZanderLogo";
import {
  CUSTOM_DYNAMIC_REASON,
  CUSTOM_STATIC_REASON,
  generatePartnerData,
} from "./utils/generatePartnerData";

const DeclineHeader = styled(H1)`
  margin-bottom: ${(props) => props.theme.spacing.xsmall};
`;

const CenteredLayout = styled.div`
  text-align: center;
`;

const defaultDeclineReason = "default_message";

/**
 * @param currentPartner:string
 * @param declineReason:string
 * @param isAgent
 * @param flags - LD
 * @param flagOverrides - manual flag values spread on top of the flags coming from LD
 * @param queryParamData - object with any data that partner links can consume
 * @param additionalDeclineVerbiage - This is an escape hatch for SE to use for the time being until all declines can be reconciled in the data here
 * @returns {*}
 * @constructor
 */

interface Props {
  currentPartner?: string;
  declineReason?: string;
  displayName: string;
  email: string;
  telephone: string;
  declineContent: string;
  link: string;
  isAgent?: boolean;
  flags?: { [key: string]: string };
  flagOverrides?: { [key: string]: string };
  queryParamData?: { [key: string]: string };
  additionalDeclineVerbiage?: { [key: string]: string };
}

const Decline: React.FC<Props> = ({
  currentPartner,
  declineReason = defaultDeclineReason,
  displayName,
  email,
  telephone,
  declineContent,
  link,
  isAgent = false,
  flags = {},
  flagOverrides = {},
  queryParamData,
  additionalDeclineVerbiage = {},
}) => {
  const finalFlagValues = { ...flags, ...flagOverrides };

  const shouldUsePartnerTemplate = Boolean(
    currentPartner === keyMappings.policyGenius ||
    currentPartner === "quote" ||
    currentPartner === keyMappings.quotacy ||
    currentPartner === keyMappings.theBaileyGroup
  );

  const isZander = currentPartner === keyMappings.zander;

  const reason =
    Object.keys(declineVerbiage).indexOf(declineReason) !== -1
      ? declineReason
      : displayName
      ? CUSTOM_DYNAMIC_REASON
      : CUSTOM_STATIC_REASON;

  const customDeclineData = generatePartnerData({
    currentPartner,
    displayName,
    email,
    telephone,
    link,
    dynamicPartner: flags.defaultDeclineScreen,
    queryParamData,
  });

  const partnerDeclineData = getPartnerDeclineData(
    currentPartner,
    finalFlagValues.defaultDeclineScreen,
    isAgent
  );

  const combinedDeclineVerbiage = {
    ...get(partnerDeclineData, "verbiage", {}),
    ...normalizeAdditionalVerbiage(additionalDeclineVerbiage),
  };

  const currentDeclineVerbiage = () => {
    return get(
      combinedDeclineVerbiage,
      declineReason,
      (combinedDeclineVerbiage as any)[defaultDeclineReason]
    );
  };

  const customVerbiage = {
    ...(declineVerbiage[reason] || {}),
    ...normalizeAdditionalVerbiage(additionalDeclineVerbiage),
  };

  const verbiage = shouldUsePartnerTemplate
    ? currentDeclineVerbiage()
    : customVerbiage;

  const renderDeclineContent = () => {
    const renderPartnerTemplate =
      shouldUsePartnerTemplate && !get(declineVerbiage, "noAffiliate");

    const partnerDeclineData = getPartnerDeclineData(
      currentPartner,
      finalFlagValues.defaultDeclineScreen,
      isAgent
    );

    if (renderPartnerTemplate) {
      return (
        <PartnerCTA
          link={partnerDeclineData.link}
          cta={partnerDeclineData.cta}
          logo={partnerDeclineData.logo}
          width={partnerDeclineData.width}
          queryParamData={queryParamData}
        />
      );
    } else {
      return <CustomDeclineCTA partnerData={customDeclineData} />;
    }
  };

  return (
    <Fragment>
      <CenteredLayout>
        <DeclineHeader data-cy="declineHeader" brand>
          {verbiage.title}
        </DeclineHeader>
        {shouldUsePartnerTemplate && (
          <DeclineDescription
            descriptionText={verbiage.description.map(
              replaceTemplateKeywords(partnerDeclineData)
            )}
          />
        )}
        {!shouldUsePartnerTemplate && (
          <DeclineDescription
            descriptionText={verbiage.description.map(
              replaceTemplateKeywords(customDeclineData)
            )}
          />
        )}
        {renderDeclineContent()}
        {isZander && <ZanderLogo telephone={telephone} />}
      </CenteredLayout>
    </Fragment>
  );
};

export const DeclineView = Decline;
