import styled from "styled-components";

export const ResponsiveWrapper = styled.div`
  width:100%;
  @media only screen and (min-width: ${props => props.theme.breakpoints.xs}) {
    padding: ${props => props.theme.spacing.xsmall};
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: ${props => props.theme.spacing.small};
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.m}) {
    padding: ${props => props.theme.spacing.medium};
    padding-top: 2rem;
  }
`;
