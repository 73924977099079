import queryString from "query-string";

const POLICY_GENIUS_ROOT_URL = "http://policygenius.go2cloud.org/aff_c";
//TODO: add unit test for this - but first need to verify url format with Marketing/Matt Gratt(?)

interface PolicyGeniusUrlParams {
  gender: string;
  birthdate: string;
  height: number;
  weight: number;
  zip: string;
  isSmoker: boolean;
  coverage: string;
  termChoice: string;
}

export const generatePolicyGeniusUrl = ({
  gender,
  birthdate,
  height,
  weight,
  zip,
  isSmoker,
  coverage,
  termChoice
}: PolicyGeniusUrlParams) => {
  const offer_id = 46;
  const aff_id = 1498;
  const redirect_slug = "bestowlife";
  const external_handoff = true;
  const query = queryString.stringify({
    offer_id,
    aff_id,
    external_handoff,
    redirect_slug,
    date_of_birth: birthdate,
    zip_code: zip,
    history_of_tobacco_use: isSmoker,
    coverage_amount: coverage,
    termChoice,
    gender,
    height_inches: height,
    weight
  });

  return `${POLICY_GENIUS_ROOT_URL}?${query}`;
};
