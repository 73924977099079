import { fetchQuotePartner } from "../../../api/quote";
import { pick } from "lodash";

const getCleanedResponse = (response: any = {}) => {
  const valid = ["name", "display_name", "email", "telephone", "custom_decline_content", "custom_link_out"];
  const partner = pick((response.partner || {}), valid);
  return { name: response.name, partner };
};

const getQuotePartner = async (qid) => {
  try {
    const apiResult = await fetchQuotePartner(qid);
    const cleanedResult = getCleanedResponse(apiResult);
    return cleanedResult;
  } catch (e) {
    console.error({ error: "Error while fetching partner data", message: e });
    return {};
  }
};

export default getQuotePartner;
