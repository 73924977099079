import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, Paragraph } from "@bestowinc/level19";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";

const ContactWrapper = styled.div`
  width: 100%;
  margin: 10px 0 ${(props) => props.theme.spacing.xsmall} 0;
`;

const CallUs = styled.div`
  color: rgb(11, 53, 65);
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: 0.4px;

  :hover {
    cursor: pointer;
    color: #5ebfb8;
  }
`;

const CallWrapper = styled.div`
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
`;

const Call = styled.span`
  color: rgb(77, 74, 73);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: right;
  cursor: default;
  text-decoration: none;
`;

const EmailWrapper = styled.div`
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
`;

const Email = styled.div`
  width: 43px;
  height: 15px;
  color: rgb(11, 53, 65);
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: 0.4px;

  :hover {
    cursor: pointer;
    color: #5ebfb8;
  }
`;

const Separator = styled(Paragraph)`
  color: rgb(77, 74, 73);
  font-size: 12px;
  font-family: Montserrat;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 16px;
  margin-bottom: 1px;
`;

const LogoWrapper = styled.div`
  display: inline-flex;
  width: 300px;
  height: 67px;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const EmailLink = styled(Link)`
  padding: 1px;
`;

interface Props {
  partnerData: any;
}

export const CustomDeclineCTA: React.FC<Props> = ({ partnerData }) => {
  const [formattedNumber, setFormattedNumber] = useState<string>("");
  const hasEmail = Boolean(
    partnerData.email !== "" && partnerData.email !== undefined
  );
  const hasPhone = Boolean(
    partnerData.telephone !== "" && partnerData.telephone !== undefined
  );
  const hasLinkOut = Boolean(
    partnerData.link !== "" && partnerData.link !== undefined
  );
  const hasExt = Boolean(
    partnerData.ext !== "" && partnerData.ext !== undefined
  );

  useEffect(() => {
    if (hasPhone) {
      setFormattedNumber(formatPhoneNumber(partnerData.telephone));
    }
  }, [hasPhone, partnerData.telephone]);

  return (
    <>
      <ContactWrapper>
        {hasPhone && (
          <CallWrapper>
            <Call>Call</Call>{" "}
            <Link
              href={`tel:${partnerData.telephone}`}
              style={{ textDecoration: "none" }}
            >
              <CallUs>
                {formattedNumber} {hasExt && `Ext ${partnerData.ext}`}
              </CallUs>
            </Link>
          </CallWrapper>
        )}
        <EmailWrapper>
          {hasPhone && hasEmail && <Separator>or</Separator>}
          {hasEmail && (
            <EmailLink href={`mailto:${partnerData.email}`}>
              <Email>Email</Email>
            </EmailLink>
          )}
        </EmailWrapper>
      </ContactWrapper>
      {hasLinkOut && (
        <Link href={partnerData.link} target="_blank" rel="noopener noreferrer">
          <LogoWrapper>
            <img src={partnerData.link} alt="" />
          </LogoWrapper>
        </Link>
      )}
    </>
  );
};
