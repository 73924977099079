import get from "lodash/get";

export const listenForHeightRequest = () => {
  try {
    window.addEventListener(
      "message",
      event => {
        if (event.data === "get-widget-height") {
          const topNode = document.querySelector(".bestow-top-node");
          const height = get(topNode, "style.height", "0px");
          window.parent.postMessage(JSON.stringify({ height }), "*");
        }
      },
      false
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};
