export async function ErrorHandler(response: Response) {
  if (response.ok) {
    return await response.json();
  }
  throw await response.json();
}

export async function Fetcher(url: string, config: RequestInit) {
  return fetch(url, config).then(ErrorHandler);
}
