import { PartnerLink, PartnerPhoneLink } from "../components/PartnerLinks";
import defaultDeclineVerbiage from "./defaultDeclineVerbiage.json";
import tbgDeclineVerbiage from "./tbgDeclineVerbiage.json";
import noPartnerNameVerbiage from "./noPartnerNameVerbiage.json";
import React from "react";
import { IconTheBaileyGroup } from "../Icons/IconTheBaileyGroup";
import { Link, LinkArrowIcon } from "@bestowinc/level19";
import { IconHub } from "../Icons/IconHub";
import { IconQuotacy } from "../Icons/IconQuotacy";
import { IconPolicyGenius } from "../Icons/IconPolicyGenius";
import { optimizePhoneUrl } from "../../../utils/optimizePhoneUrl";
import { keyMappings } from "./partnerKeyMappings";
import merge from "lodash/merge";
import { generatePolicyGeniusUrl } from "../utils/generatePolicyGeniusUrl";
import agentDeclineVerbiage from "./agentDeclineVerbiage";

//Generic constant values like phone numbers and emails
const constants = {
  defaultPartner: keyMappings.policyGenius,
  [keyMappings.theBaileyGroup]: {
    telNumber: "866-207-8988",
    email: "concierge@thebaileygp.com",
    name: "The Bailey Group"
  },
  [keyMappings.hubInternational]: {
    telNumber: "1-(855) 604-8483",
    name: "HUB International"
  },
  [keyMappings.policyGenius]: {
    name: "Policygenius"
  },
  [keyMappings.quotacy]: {
    telNumber: "(844) 786-8229",
    name: "Quotacy"
  },
  [keyMappings.woodforest]: {
    telNumber: "1-866-659-6703",
    name: "Woodforest"
  },
  [keyMappings.rapidLifeInsurance]: {
    telNumber: "1-866-658-6184",
    name: "Rapid Life Insurance"
  },
  [keyMappings.safeAuto]: {
    telNumber: "1-866-658-6117",
    name: "SafeAuto"
  },
  [keyMappings.isthmus]: {
    telNumber: "1-866-658-6184",
    name: "Isthmus"
  },
  [keyMappings.tavenner]: {
    telNumber: "1-800-543-6922",
    name: "Tavenner"
  },
  [keyMappings.sproutt]: {
    telNumber: "1-888-786-3917",
    name: "Sproutt",
    url: "https://www.sproutt.com"
  },
  [keyMappings.transcendbg]: {
    telNumber: "1-954-663-1376",
    name: "TranscendBG",
    email: "acortesio@transcendbg.com"
  }
};

//Template configs
const templates = {
  //The Bailey Group
  [keyMappings.theBaileyGroup]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.theBaileyGroup].telNumber}
      />
    ),
    logo: <IconTheBaileyGroup width="280px" />,
    width: "280px",
    link:
      "tel:" +
      optimizePhoneUrl(constants[keyMappings.theBaileyGroup].telNumber),
    verbiage: tbgDeclineVerbiage,
    templateReplacements: {
      "@TBGEmail": (
        <Link
          style={{ padding: 0 }}
          href={"mailto:" + constants[keyMappings.theBaileyGroup].email}
        >
          {constants[keyMappings.theBaileyGroup].email}
        </Link>
      ),
      "@TBGPhone": (
        <Link
          style={{ padding: 0 }}
          href={
            "tel:" +
            optimizePhoneUrl(constants[keyMappings.theBaileyGroup].telNumber)
          }
        >
          {constants[keyMappings.theBaileyGroup].telNumber}
        </Link>
      )
    }
  },
  [keyMappings.hubInternational]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.hubInternational].telNumber}
      />
    ),
    logo: <IconHub width="180px" />,
    width: "180px",
    link:
      "tel:" +
      optimizePhoneUrl(constants[keyMappings.hubInternational].telNumber),
    verbiage: defaultDeclineVerbiage
  },
  [keyMappings.policyGenius]: {
    //policyGeniusUrl is dynamically provided down as props to the component originating in generatePolicyUrl
    // The reason for this is that it needs to have certain query params attached to it.
    // This function accepts query param data and generates a link from it
    link: generatePolicyGeniusUrl,
    cta: <PartnerLink>Find coverage now</PartnerLink>,
    logo: <IconPolicyGenius width="180px" />,
    width: "180px",
    verbiage: defaultDeclineVerbiage
  },
  [keyMappings.quotacy]: {
    link: "https://www.quotacy.com/bestow",
    cta: <PartnerLink>Find coverage now</PartnerLink>,
    logo: <IconQuotacy width="180px" />,
    width: "180px",
    verbiage: defaultDeclineVerbiage
  },
  [keyMappings.woodforest]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.woodforest].telNumber}
      />
    ),
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.rapidLifeInsurance]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.rapidLifeInsurance].telNumber}
      />
    ),
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.safeAuto]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.safeAuto].telNumber}
      />
    ),
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.isthmus]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.isthmus].telNumber}
      />
    ),
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.tavenner]: {
    cta: (
      <PartnerPhoneLink
        phoneNumber={constants[keyMappings.tavenner].telNumber}
      />
    ),
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.transcendbg]: {
    cta: {
      telNumber: (
        <Link
          target="_blank"
          href={`tel:${constants[keyMappings.transcendbg].telNumber}`}
          loading={false}
        >
          Call {constants[keyMappings.transcendbg].telNumber}
        </Link>
      ),
      email: (
        <Link
          target="_blank"
          href={`mailto:${constants[keyMappings.transcendbg].email}`}
          loading={false}
        >
          Email
        </Link>
      )
    },
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.sproutt]: {
    cta: {
      telNumber: (
        <Link
          iconRight={<LinkArrowIcon />}
          target="_blank"
          href={`tel:${constants[keyMappings.sproutt].telNumber}`}
          loading={false}
        >
          Call {constants[keyMappings.sproutt].telNumber}
        </Link>
      ),
      url: (
        <Link
          iconRight={<LinkArrowIcon />}
          target="_blank"
          href={constants[keyMappings.sproutt].url}
          loading={false}
        >
          Find coverage now
        </Link>
      )
    },
    verbiage: noPartnerNameVerbiage
  },
  [keyMappings.agent]: {
    verbiage: agentDeclineVerbiage
  }
};

// The following partners are agents, but have their own bestow.com/{partner} page
export const AGENT_PARTNERS = [
  "megfinancial",
  "taber",
  "cornett",
  "billcook",
  "markwall",
  "pinney",
  "insurancedivision",
  "kellysanner",
  "melschlesinger",
  "creativefinancialstrategies",
  "ipa-ok",
  "leblanc",
  "patternlife",
  "jrc",
  "iketrotter",
  "agentquote",
  "aimcor",
  "aimcorenterprise",
  "tickit",
  "tickitdirect",
  "efinancial",
  "covr",
  "brokerscentral",
  "navyfederalcreditunion",
  "bbva"
];

const mergedPartnerData = merge(constants, templates);
export const PARTNERS = { keyMappings, ...mergedPartnerData };
