import { replaceTemplateString } from "./replaceTemplate";
import { Link, Paragraph } from "@bestowinc/level19";
import { optimizePhoneUrl } from "./optimizePhoneUrl";
import React from "react";
import { BESTOW } from "../constants/bestowConstants";
import { upperFirst, camelCase } from "lodash";
import styled from "styled-components";

const IconLink = styled.a<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: auto;
`;

const OrParagraph = styled(Paragraph)`
  padding-top: 4px;
`;

const resolvedLink = (link, queryParamData) =>
  typeof link === "function" ? link(queryParamData || {}) : link;

/**
 * Returns templates, but dynamically populates the partner name with the current partner
 * @param currentPartner
 * @returns {{"@BestowEmail": *, "*Name": (*|string), "@BestowPhone": *}}
 */
const globalReplacementMappings = ({
  name,
  displayName,
  email,
  link,
  custom_link_out,
  width,
  telephone,
  logo,
  dynamicPartner,
  queryParamData,
}) => ({
  "@Partner": name || "",
  "@BestowEmail": BESTOW.email,
  "@BestowEmailLink": (
    <Link
      key={BESTOW.email}
      style={{ padding: 0 }}
      href={"mailto:" + BESTOW.email}
    >
      {BESTOW.email}
    </Link>
  ),
  "@BestowPhone": BESTOW.phone,
  "@BestowPhoneLink": (
    <Link
      key={BESTOW.phone}
      style={{ padding: 0 }}
      href={"tel:" + optimizePhoneUrl(BESTOW.phone)}
    >
      {BESTOW.phone}
    </Link>
  ),
  "@LinkOut":
    link || custom_link_out ? (
      <a href={resolvedLink(link || custom_link_out, queryParamData)}>
        {link || custom_link_out}
      </a>
    ) : (
      ""
    ),
  "@EmailLink": email ? <a href={"mailto:" + email}>{email}</a> : "",
  "@PhoneLink": telephone ? <a href={"tel:" + telephone}>{telephone}</a> : "",
  "@LogoLink":
    logo && link ? (
      <IconLink href={resolvedLink(link, queryParamData)} width={width}>
        {logo}
      </IconLink>
    ) : (
      ""
    ),
  "@LogoPhone":
    logo && telephone ? (
      <IconLink href={"tel:" + telephone} width={width}>
        {logo}
      </IconLink>
    ) : (
      ""
    ),
  "@LogoEmail":
    logo && email ? (
      <IconLink href={"mailto:" + email} width={width}>
        {logo}
      </IconLink>
    ) : (
      ""
    ),
  "@PartnerLinkOut":
    dynamicPartner && dynamicPartner.link ? (
      <a href={resolvedLink(dynamicPartner.link, queryParamData)}>
        {dynamicPartner.link}
      </a>
    ) : (
      ""
    ),
  "@PartnerLogoLink":
    dynamicPartner && dynamicPartner.link && dynamicPartner.logo ? (
      <IconLink
        href={resolvedLink(dynamicPartner.link, queryParamData)}
        width={dynamicPartner.width}
      >
        {dynamicPartner.logo}
      </IconLink>
    ) : (
      ""
    ),
  "@PartnerLogoPhone":
    dynamicPartner && dynamicPartner.logo && dynamicPartner.telephone ? (
      <IconLink href={"tel:" + dynamicPartner.telephone} width={width}>
        {dynamicPartner.logo}
      </IconLink>
    ) : (
      ""
    ),
  "@PartnerLogoEmail":
    dynamicPartner && dynamicPartner.logo && dynamicPartner.email ? (
      <IconLink href={"mailto:" + dynamicPartner.email} width={width}>
        {dynamicPartner.logo}
      </IconLink>
    ) : (
      ""
    ),
  "@Or": <OrParagraph>or</OrParagraph>,
  "@DisplayName": <strong>{displayName}</strong> || "your agent",
});

/**
 *
 * @param currentPartner
 * @returns paragraphText array of JSX
 */
export const replaceTemplateKeywords = (partnerData: any = {}) => {
  return (paragraphText) => {
    const toMap = {
      ...globalReplacementMappings(partnerData),
      ...partnerData.templateReplacements,
    };
    const mappings = Object.keys(toMap).reduce(
      (obj, key) => ({
        ...obj,
        [`@${upperFirst(camelCase(key))}`]: toMap[key],
      }),
      {}
    );

    for (const replacementString in mappings) {
      paragraphText = replaceTemplateString(
        paragraphText,
        replacementString,
        mappings[replacementString]
      );
    }
    return paragraphText;
  };
};
