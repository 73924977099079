import { flattenDeep } from "lodash";

//Returns either a string or an array depending on what was passed in. Basically just some format of JSX text
export function replaceTemplateWithString(
  templateToReplace: any,
  replacementString: string,
  newValue: string,
) {
  if (Array.isArray(templateToReplace)) {
    for (let i in templateToReplace) {
      if (typeof templateToReplace[i] === "string") {
        templateToReplace[i] = templateToReplace[i].replace(
          replacementString,
          newValue
        );
      }
    }
  } else if (typeof templateToReplace === "string") {
    templateToReplace = templateToReplace.replace(replacementString, newValue);
  }
  return templateToReplace;
}

//Returns an array with the string value replaced as a component
function replaceStringWithComponent(
  stringToReplace: string,
  replacementString: string,
  component: any,
) {
  let splitText = stringToReplace.split(new RegExp(`(${replacementString})`));
  if (splitText.length > 1) {
    for (let i = 1; i < splitText.length; i += 2) {
      splitText[i] = component;
    }
  }
  return splitText;
}

//Takes either a string or an array of strings/JSX tmpls
export const replaceTemplateWithComponent = (
  content: any[],
  replacementString: string,
  component: any,
) => {
  if (Array.isArray(content)) {
    for (let i in content) {
      if (typeof content[i] === "string") {
        content[i] = replaceStringWithComponent(
          content[i],
          replacementString,
          component
        );
      }
    }
  } else if (typeof content === "string") {
    content = replaceStringWithComponent(content, replacementString, component);
  }
  return flattenDeep(content);
};

export const replaceTemplateString = (
  content: any,
  replacementString: string,
  replacementVal: string,
) => {
  if (typeof replacementVal === "string") {
    content = replaceTemplateWithString(
      content,
      replacementString,
      replacementVal
    );
  } else {
    content = replaceTemplateWithComponent(
      content,
      replacementString,
      replacementVal
    );
  }
  return content;
};
