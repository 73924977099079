import React, { Component } from "react";
import { Level19ThemeProvider } from "@bestowinc/level19";
import { DeclineView } from "./DeclineView"
import queryString from "query-string"
import getQuotePartner from "./utils/getQuotePartner";
import { get } from "lodash";
import { segmentTrack } from "../../utils";
import { ResponsiveWrapper } from "./components/ResponsiveWrapper";
import styled from "styled-components";

const RelativeDiv = styled.div`
  position: relative;
`;

interface Props {
  declineReason?: string;
  quoteId?: string;
  queryParamData?: {[key: string]: string};
  flagOverrides?: {[key: string]: string};
  currentPartner?: string;
}

class DeclineComponent extends Component<Props> {
    state = {
      name: "",
      quoteId: "",
      displayName: "",
      email: "",
      telephone: "",
      link: "",
      declineContent: "",
      declineReason: "",
      loading: true,
    }
    componentDidMount() {
      const query = queryString.parse(window.location.search);

      // the standalone page may have a decline_reason in the query,
      // otherwise, after a quote submit, the props will recieve a declineReason
      const declineReason = query.decline_reason || this.props.declineReason;
      if (declineReason) {
        this.setState({ declineReason: declineReason });
      }
      // the standalone page will have the quote_id in the query,
      // otherwise, after a quote submit, the quoteId should be a provided prop
      const quoteId = query.quote_id || this.props.quoteId;
      if (quoteId) {
        this.setState({ quoteId });
      }

      if(quoteId) {
        return getQuotePartner(quoteId)
          .then(data => {
            this.setState({
              currentPartner: get(data, "partner.name"),
              name: get(data, "partner.name"),
              displayName: get(data, "partner.display_name"),
              email: get(data, "partner.email"),
              telephone: get(data, "partner.telephone"),
              declineContent: get(data, "partner.custom_decline_content"),
              link: get(data, "partner.custom_link_out"),
            })
            segmentTrack("decline_page_viewe", Object.assign(data, { quote_id: query.quote_id }));
          })
          .finally(() => this.setState({ loading: false }));
      } else {
        this.setState({ loading: false });
        segmentTrack("decline_page_viewe", { quote_id: query.quote_id });
      }
    }

    goBack() {
      window.history.back();
    }

    render() {
        if (this.state.loading) { return "Loading..." }

        return (
          <Level19ThemeProvider>
            <ResponsiveWrapper>
              <RelativeDiv>
                <DeclineView
                  {...this.props}
                  {...this.state}
                />
              </RelativeDiv>
            </ResponsiveWrapper>
          </Level19ThemeProvider >
        );
    }
}

export const Decline = DeclineComponent;
