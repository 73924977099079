import React, { Fragment } from "react";
import styled from "styled-components";

const PartnerLinkIcon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: auto;
`;

/**
 * Renders a partner Call to Action with their Logo above their link with some link text.
 * Both Logo and link text link to their site
**/

interface Props {
  link: Function | string;
  width: string;
  logo: React.ReactElement;
  cta: React.ReactElement;
  queryParamData: any;
}

export const PartnerCTA: React.SFC<Props> = ({ link, width, logo, cta, queryParamData }) => {
  const resolvedLink =
    typeof link === "function" ? link(queryParamData || {}) : link;

  return (
    <Fragment>
      <PartnerLinkIcon href={resolvedLink}>
        {logo}
        {cta}
      </PartnerLinkIcon>
    </Fragment>
  );
};
