//Keys that map to each partner. These are also what shows up in the url to trigger a partner decline
//Originally this was in partnerConstants.js but caused some cyclical dependency issues when I imported them into partner icons
//So now these are their own separate file
export const keyMappings = {
  policyGenius: "policyGenius" as 'policyGenius',
  theBaileyGroup: "tbg" as "tbg",
  hubInternational: "hub" as 'hub',
  quotacy: "quotacy" as 'quotacy',
  woodforest: "woodforest" as 'woodforest',
  tavenner: "tavenner" as 'tavenner',
  safeAuto: "safeauto" as 'safeauto',
  rapidLifeInsurance: "RLIQ" as 'RLIQ',
  isthmus: "isthmus" as 'isthmus',
  sproutt: "sproutt" as 'sproutt',
  transcendbg: "transcendbg" as 'transcendbg',
  agent: "agent" as "agent",
  zander: "zanderinsurance" as "zanderinsurance",
};
