export const setHighestContainerSize = () => {
  try {
    const bestowNode = document.getElementsByClassName(
      "bestow-top-node"
    )[0];
    const parentNode = bestowNode.parentNode as any;
    const parentBounds = parentNode.getBoundingClientRect();
    const formNode = document.getElementsByClassName(
      "bestow-form-container"
    )[0];
    const formHeight = formNode.getBoundingClientRect().height;
    const setHeightValue =
      formHeight > parentBounds.height ? formHeight : parentBounds.height;
    bestowNode.setAttribute(
      "style",
      `height: ${setHeightValue}px; width: ${parentBounds.width}px`
    );
  } catch (e) {
  }
};

export const setContainerSizeToForm = () => {
  const bestowNode = document.getElementsByClassName(
    "bestow-top-node"
  )[0];
  try {
    const parentNode = bestowNode.parentNode as any;
    const parentBounds = parentNode.getBoundingClientRect();
    const formNode = document.getElementsByClassName(
      "bestow-form-container"
    )[0];
    const formHeight = formNode.getBoundingClientRect().height;
    bestowNode.setAttribute(
      "style",
      `height: ${formHeight}px; width: ${parentBounds.width}px`
    );
  } catch (e) {
  }
};
