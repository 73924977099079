//eslint-disable-next-line
import { env } from '../interfaces';

const SNIPPET_URL_MAPPINGS = {
  qa: "/new-relic-snippets/new-relic-qa.min.js",
  staging: "/new-relic-snippets/new-relic-staging.min.js",
  production: "/new-relic-snippets/new-relic-production.min.js",
  dev: undefined,
};

// default env is "dev", which w/out a mapping will currently retun undefined,
// so anything consuming this should include a check

export const getNewRelicSnippet = (env: env) => SNIPPET_URL_MAPPINGS[env || "dev"];
