import { Fetcher } from "./core";
import { ShortUrlData, FetchQuoteTypes } from '../interfaces/quote';

export async function fetchQuote(body: Object | ShortUrlData, type: FetchQuoteTypes) {
  const url = type === 'long'
  ? `${process.env.REACT_APP_TAKE_TWO_API_URL}/quote?enable_2019_plus=true`
  : `${process.env.REACT_APP_AGS_API_URL}/agent-quote`;

  return Fetcher(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  }).then(result => {
    const { product_rates: rates, ...rest } = result;
    if (rates) {
      const productRates = Object.keys(rates).reduce((acc, val) => {
        acc[val] = Object.keys(rates[val]).reduce((rateAcc, rateVal) => {
          rateAcc[rateVal] = parseFloat(rates[val][rateVal]);
          return rateAcc;
        }, {} as {[rateVal: string]: number});
        return acc;
      }, {} as any);
      return { ...rest, product_rates: productRates };
    }
    return result;
  });
}

export async function fetchProductsForState(state: string, request2019Quote: boolean) {
  const queryParams = `enable_2019_plus=true&get_2019=${request2019Quote?'true':'false'}`
  return Fetcher(`${process.env.REACT_APP_TAKE_TWO_API_URL}/product/${state}?${queryParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export async function fetchQuotePartner(quoteId: string) {
  return Fetcher(`${process.env.REACT_APP_TAKE_TWO_API_URL}/quote/${quoteId}/partner`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export async function setQuoteEmail(body: Object, quoteId: string) {
  return Fetcher(`${process.env.REACT_APP_TAKE_TWO_API_URL}/quote/${quoteId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });
}

export async function fetchAgent(agentCode: string) {
  return Fetcher(`${process.env.REACT_APP_AGS_API_URL}/agent/${agentCode}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
